<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <b-card header-tag="header" footer-tag="footer">
        <b-card-header>
          <b-row>
            <b-col cols="6">
              <i class="fa fa-align-justify"></i><strong> {{ user.username }} </strong><small> genel özet</small>
            </b-col>
            <b-col cols="2">
              <template v-if="user.permissions_result && user.permissions_result.indexOf('admin')">
                <b-form-select v-model="d_userType">
                  <option value="this">Aktif Kullanıcı</option>
                  <option value="all">Tüm Kullanıcılar</option>
                </b-form-select>
              </template>
            </b-col>
            <b-col cols="2">
                <b-form-select v-model="d_autoUpdate">
                  <option value="yes">otomatik güncelle</option>
                  <option value="no">otomatik güncelleme</option>
                </b-form-select>
            </b-col>
          </b-row>
        </b-card-header>
        <div>
          <div :key="summary_index" v-for="(summary, summary_index) in d_summaryList" class="row mb-1" style="min-height: 30px;">
            <div class="col-sm-4">{{ summary.label }}:</div>
            <div class="col-sm-8 pt-1">
              <b-progress v-if="d_showPageData" :value="summary.value" show-value variant="primary" :key="'summary_' + summary_index.toString()"></b-progress>
            </div>
          </div>
        </div>
      </b-card>
      <b-card bg-variant="white" no-body>
        <b-card-header>
          <i class="fa fa-align-justify"></i> Analizler
        </b-card-header>
        <b-row>
          <b-col cols="12">
            <div style="overflow-x: hidden; overflow-y: auto; height: 750px;" id="chart_content">
              <template v-for="(chart, chart_index) in d_chartList">
                <div :style="f_calculateChartStyle(chart)">
                  <b-card no-body bg-variant="white">
                    <b-card-header class="p-1">
                      <i class="fa fa-align-justify"></i> {{ chart.name }}
                    </b-card-header>
                    <div v-if="chart.type === 'doughnut'" class="chart-wrapper">
                      <doughnut-view v-if="d_showPageData" :chartId="'chart_' + chart_index.toString()" :chart_data="chart.chart_data" />
                    </div>
                    <div v-if="chart.type === 'pie'" class="chart-wrapper">
                      <pie-view v-if="d_showPageData" :chartId="'chart_' + chart_index.toString()" :chart_data="chart.chart_data" />
                    </div>
                    <div v-if="chart.type === 'bar'" class="chart-wrapper">
                      <!--
                        <div style="position: relative; height:250px; width:500px;">
                          <bar-view :chartId="'chart_' + chart_index.toString()" :chart_data="chart.chart_data" :height="250" :width="500" />
                        </div>
                      -->
                      <bar-view v-if="d_showPageData" :chartId="'chart_' + chart_index.toString()" :chart_data="chart.chart_data" />
                    </div>
                  </b-card>
                </div>
              </template>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card header-tag="header" footer-tag="footer">
        <div slot="header">
          <i class="fa fa-align-justify"></i><strong> Projeler </strong> <small><code>liste</code> özellikler</small>
        </div>
        <div role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-row style="padding: 5px;">
                <!-- <b-col sm="12" md="11" @click="f_selectThisProject(project_ind)" @dblclick="go_to_this_project_details(project.id)"> -->
                <b-col sm="12" md="3">
                  Proje Adı
                </b-col>
                <b-col sm="12" md="1">
                  Oluşturan
                </b-col>
                <b-col sm="12" md="1">
                  Tarih
                </b-col>
                <b-col sm="12" md="1">
                  Toplam Satır
                </b-col>
                <b-col sm="12" md="1">
                  Kolon Adedi
                </b-col>
                <b-col sm="12" md="3">
                  Veri Hazırlama
                </b-col>
                <b-col sm="12" md="2"></b-col>
              </b-row>
            </b-card-header>
          </b-card>
          <template v-for="(project, project_ind) in d_projectList">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-row v-b-toggle="'accordion_' + project_ind.toString()" class="" :style="d_selectedProjectInd === project_ind ? 'background-color: #e6fddf; cursor: pointer; padding: 5px;' : 'cursor: pointer; padding: 5px;'">
                  <!-- <b-col sm="12" md="11" @click="f_selectThisProject(project_ind)" @dblclick="go_to_this_project_details(project.id)"> -->
                  <b-col sm="12" md="3">
                    <strong>{{ project_ind + 1 }} - {{ project.name }} </strong>
                  </b-col>
                  <b-col sm="12" md="1">
                    {{ project.owner }}
                  </b-col>
                  <b-col sm="12" md="1">
                    {{ DateFormatWithTime(project.date) }}
                  </b-col>
                  <b-col sm="12" md="1">
                    {{ project.total_cases }}
                  </b-col>
                  <b-col sm="12" md="1">
                    {{ project.column_count }}
                  </b-col>
                  <b-col sm="12" md="3">
                    <template v-if="project.data && project.data.data && project.data.data.general && project.data.data.general.data_updating_status && project.data.data.general.data_updating_status">
                      {{ project.data.data.general.data_updating_status.val.label }}
                    </template>
                    <template v-else>
                      -
                    </template>
                  </b-col>
                  <b-col sm="12" md="2">
                    <b-btn block @click="f_goToWisdomStats(project.id)" variant="info">projeye git</b-btn>
                    <!-- <b-dropdown variant="link" size="lg" no-caret>
                      <template style="" slot="button-content">o: Sized
                        &#x1f50d;
                      </template>
                      <b-dropdown-item @click="f_goToWisdomStats(project.id)"> Wanalyzer Projesine Git </b-dropdown-item>
                      <b-dropdown-item @click="f_deletePolestarProject(project.id)"> Projeyi Sil </b-dropdown-item>
                    </b-dropdown> -->
                  </b-col>
                </b-row>
              </b-card-header>
              <b-collapse :id="'accordion_' + project_ind.toString()" :visible="false" accordion="my-accordion" role="tabpanel">
                <b-card-body style="background: #e8fffc;">
                  <b-row style="padding: 5px;">
                    <b-col sm="12" md="4">
                      <b-row style="padding: 5px;">
                        <b-col sm="12" md="12">
                          <strong>ID:</strong> {{ project.id }}
                        </b-col>
                      </b-row>
                      <template v-if="project.data">
                        <b-row style="padding: 5px;">
                          <b-col sm="12" md="12">
                            <strong>Ana Algoritma Kuralı:</strong>
                            <template v-if="f_thereIsMainRuleAlgorithm(project.data)">
                              Evet
                            </template>
                            <template v-else>
                              Hayır
                            </template>
                          </b-col>
                        </b-row>
                        <b-row style="padding: 5px;">
                          <b-col sm="12" md="12">
                            <strong>Proje Veri Tipi</strong>
                            <template v-if="project.data.data.general.project_data_type">
                              {{ project.data.data.general.project_data_type.val.label }}
                            </template>
                          </b-col>
                        </b-row>
                        <b-row style="padding: 5px;">
                          <b-col sm="12" md="12">
                            <strong>Otomatik veri güncelleme</strong>
                            <template v-if="project.data.data.settings && project.data.data.settings.auto_update">
                              {{ project.data.data.settings.auto_update.val.label }}
                            </template>
                            <template>
                              -
                            </template>
                          </b-col>
                        </b-row>
                        <b-row style="padding: 5px;">
                          <b-col sm="12" md="12">
                            <strong>Otomatik veri güncelleme tipi</strong>
                            <template v-if="project.data.data.settings && project.data.data.settings.update_type">
                              {{ project.data.data.settings.update_type.val.label }}
                            </template>
                            <template>
                              -
                            </template>
                          </b-col>
                        </b-row>
                        <b-row style="padding: 5px;">
                          <b-col sm="12" md="12">
                            <strong>Otomatik veri güncelleme zaman döngüsü</strong>
                            <template v-if="project.data.data.settings && project.data.data.settings.interval_value">
                              {{ project.data.data.settings.interval_value.val }}
                            </template>
                            <template>
                              -
                            </template>
                            /
                            <template v-if="project.data.data.settings && project.data.data.settings.interval_type">
                              {{ project.data.data.settings.interval_type.val.label }}
                            </template>
                            <template>
                              -
                            </template>
                          </b-col>
                        </b-row>
                        <b-row style="padding: 5px;">
                          <b-col sm="12" md="12">
                            <strong>Yeni Veri Ekleme Limiti</strong>
                            <template v-if="project.data.data.general.case_limit">
                              {{ project.data.data.general.case_limit.val.label }}
                            </template>
                            <template v-else>
                              -
                            </template>
                          </b-col>
                        </b-row>
                        <b-row style="padding: 5px;">
                          <b-col sm="12" md="12">
                            <strong>Yeni veri ekleme limit değeri</strong>
                            <template v-if="project.data.data.general.limit">
                              {{ project.data.data.general.limit.val }}
                            </template>
                            <template v-else>
                              -
                            </template>
                          </b-col>
                        </b-row>
                        <b-row style="padding: 5px;">
                          <b-col sm="12" md="12">
                            <strong>Yeni veri ekleme başlangıç noktası</strong>
                            <template v-if="project.data.data.general.new_data_starting_point_type">
                              {{ project.data.data.general.new_data_starting_point_type.val.label }}
                            </template>
                            <template v-else>
                              -
                            </template>
                          </b-col>
                        </b-row>
                        <b-row style="padding: 5px;">
                          <b-col sm="12" md="12">
                            <strong>Son wmanager işlemi toplam süre (sn)</strong>
                            <template v-if="project.data.data.general.last_wmanager_operation_total_seconds">
                              {{ project.data.data.general.last_wmanager_operation_total_seconds.val }}
                            </template>
                            <template v-else>
                              -
                            </template>
                          </b-col>
                        </b-row>
                        <b-row style="padding: 5px;">
                          <b-col sm="12" md="12">
                            <strong>Son veri güncelleme işlemi toplam süre</strong>
                            <template v-if="project.data.data.general.last_data_update_total_time">
                              {{ project.data.data.general.last_data_update_total_time.val }}
                            </template>
                            <template v-else>
                              -
                            </template>
                          </b-col>
                        </b-row>
                        <b-row style="padding: 5px;">
                          <b-col sm="12" md="12">
                            <strong>İstatistik adedi</strong>
                            <template v-if="project.data.data.statistical_analyze && project.data.data.statistical_analyze.statistics && project.data.data.statistical_analyze.statistics.list && project.data.data.statistical_analyze.statistics.list">
                              {{ project.data.data.statistical_analyze.statistics.list.length }}
                            </template>
                            <template v-else>
                              0
                            </template>
                          </b-col>
                        </b-row>
                        <b-row style="padding: 5px;">
                          <b-col sm="12" md="12">
                            <strong>Makine öğrenmesi adedi</strong>
                            <template v-if="project.data.data.machine_learning && project.data.data.machine_learning.machine_learning && project.data.data.machine_learning.machine_learning.list && project.data.data.machine_learning.machine_learning.list">
                              {{ project.data.data.machine_learning.machine_learning.list.length }}
                            </template>
                            <template v-else>
                              0
                            </template>
                          </b-col>
                        </b-row>
                        <b-row style="padding: 5px;">
                          <b-col sm="12" md="12">
                            <strong>Walgorithm karar destek paketi adedi</strong>
                            <template v-if="project.data.data.dmp && project.data.data.dmp.dmp_list && project.data.data.dmp.dmp_list.list && project.data.data.dmp.dmp_list.list">
                              {{ project.data.data.dmp.dmp_list.list.length }}
                            </template>
                            <template v-else>
                              0
                            </template>
                          </b-col>
                        </b-row>
                      </template>
                    </b-col>
                    <b-col sm="12" md="4">
                      <b-row style="padding: 5px;">
                        <b-col sm="12" md="12">
                          <strong>İfade eşleştirmeleri</strong>
                        </b-col>
                      </b-row>
                      <template v-if="project.phrases">
                        <div style="overflow-x: hidden; overflow-y: auto; height: 250px;" id="chart_content">
                          <template v-for="(phrase, phrase_ind) in project.phrases">
                            <b-card no-body class="mb-1">
                              <b-card-header header-tag="header" bg-header-variant="primary" class="p-1" role="tab">
                                {{ phrase.label }}
                              </b-card-header>
                              <b-row style="padding: 5px;">
                                <b-col sm="12" md="12">
                                  <strong>İfade toplam eşleştirme</strong> {{ phrase.match_total }}
                                </b-col>
                              </b-row>
                              <b-row style="padding: 5px;">
                                <b-col sm="12" md="12">
                                  <strong>Doğru eşleştirme</strong> {{ phrase.true_match }}
                                </b-col>
                              </b-row>
                            </b-card>
                          </template>
                        </div>
                      </template>
                    </b-col>
                    <b-col sm="12" md="4">
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-collapse>
            </b-card>
          </template>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { API_FRONTEND_URL } from '@/config';
import PieView from '@/components/chart/PieView';
import LineView from '@/components/chart/LineView';
import ScatterView from '@/components/chart/ScatterView';
import BarView from '@/components/chart/BarView';
import DoughnutView from '@/components/chart/DoughnutView';
import { ClsPolestar } from "@/services/public/polestar";
import {
  default as WisdomDataShow
} from '@/components/widgets/WisdomDataShow';
import {
  default as Modal
} from "@/components/widgets/Modal";
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import { mapGetters } from 'vuex';
import OptionService from '@/services/option';
import { ClsWdmrForever } from "@/services/public/wdm";
import PoleStarService from '@/services/polestar';
import LayerService from '@/services/layer';
import moment from 'moment';
export default {
  name: 'WanalyzerProjectsDetailed',
  computed: mapGetters({
    StoreModal: 'modal',
    lang: 'lang'
  }),
  components: {
    Modal,
    WisdomDataModal,
    WisdomDataShow,
    LineView,
    ScatterView,
    BarView,
    DoughnutView,
    PieView
  },
  props: {},
  data () {
    return {
      d_autoUpdate: 'yes',
      d_userType: 'this',
      d_showPageData: true,
      d_summaryList: [],
      bars: [
        { variant: 'success', value: 75 },
        { variant: 'info', value: 75 },
        { variant: 'warning', value: 75 },
        { variant: 'danger', value: 75 },
        { variant: 'primary', value: 75 },
        { variant: 'secondary', value: 75 },
        { variant: 'dark', value: 75 }
      ],
      d_chartList: [],
      d_selectedAnatomySchemaList: [],
      d_selectedProjectInd: '',
      wisdom_data: {},
      user: {},
      option_data: {},
      wisdom_op: 'new',
      data_type: '',
      show_wisdom_data_modal: false,
      d_projectList: [],
      d_getProjectListInterval: 0,
      d_Wdm22_data: ''
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.d_Wdm22_data = JSON.parse(localStorage.getItem('wdm22'));
    let query = 'data_type=wdm3&location=wisdom';
    OptionService.get_last_wdm_of_this_type(query)
      .then(resp => {
        if (resp.data.status === 'success') {
          this.option_data['wdm3'] = resp.data.result;
        } else {
          console.log('errror ', resp.data.message);
        }
      });
    this.f_getUserPolestarProjects();
  },
  beforeMount () {},
  mounted: function () {
    this.f_getProjectDetailsByInterval();
  },
  destroyed () {
    clearInterval(this.d_getProjectListInterval);
    // clearInterval(this.d_nextDrawAlgorithm);
  },
  methods: {
    f_thereIsMainRuleAlgorithm: function (project_data) {
      let res = false;
      if (project_data.layer_main_rules) {
        for (let i in project_data.layer_main_rules) {
          if (project_data.layer_main_rules[i].query_list.length > 0) {
            res = true;
            break;
          }
        }
      }
      return res;
    },
    f_showPageData: function () {
      this.d_showPageData = false;
      setTimeout(function () {
        this.d_showPageData = true;
        this.$forceUpdate();
      }.bind(this), 50)
    },
    f_getProjectDetailsByInterval: function () {
      if (this.d_autoUpdate === 'yes') {
        this.d_getProjectListInterval = setInterval(function () {
          this.f_getUserPolestarProjects();
        }.bind(this), 30000);
      }
    },
    f_calculateChartStyle: function (chart) {
      let style = 'margin: 10px; float: left;';
      if (chart.style) {
        for (let i in chart.style) {
          style += i + ': ' + chart.style[i] + ';';
        }
        if (chart.style.minHeight) {
          style += 'min-height: ' + chart.style.minHeight;
        } else if (chart.style.height) {
          style += 'min-height: ' + chart.style.height;
        }
      } else {
        style += 'width: 300px;';
      }
      if (style.indexOf('min-height') === -1) {
        style += 'min-height: 300px;';
      }
      return style;
    },
    f_goToWisdomStats: function (project_id) {
      // this.$router.push({ path: '/polestar/wisdomstats', query: { project_id: project_id } });
      let url = API_FRONTEND_URL + '#/polestar/wisdomstats?project_id=' + project_id;
      window.open(url, '_blank');
    },
    f_deletePolestarProject: function (wdm3_id) {
      let modal_data = { 'type': 'confirm', 'text': 'Projeyi silmek istediğinize emin misiniz?', 'centered': true, 'title': 'Proje Silme İşlemi Hakkında', 'function_name': 'f_deletePolestarProjectConfirmed', 'confirm': false, 'arguments': [wdm3_id] };
      this.$store.commit('modal', modal_data);
      this.$store.commit('modal_show', true);
    },
    f_deletePolestarProjectConfirmed: function (args) {
      let data = { 'wdm3_id': args[0] };
      PoleStarService.delete_polestar_project(data).then(resp => {
        if (resp.data.status === "success") {
          let del = '';
          for (let i in this.d_projectList) {
            if (this.d_projectList[i].id === args[0]) {
              del = i;
            }
          }
          this.d_projectList.splice(del, 1);
          this.$forceUpdate();
        } else {}
        this.$forceUpdate();
      });
    },
    DateFormat: function (date) {
      return moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY');
    },
    DateFormatWithTime: function (date) {
      return moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY hh:mm');
    },
    go_to_this_project_details: function (project_id) {
      this.$router.push({ path: '/polestar/wisdomstats', query: { project_id: project_id } });
      // this.$router.push({ path: '/polestar/projects/details', query: { project_id: project_id } });
    },
    f_scrollCaseTable: function () {
      var content = document.getElementById("case-table");
      // var scrolled = document.scrollingElement.scrollTop;
      // console.log('scrolled', scrolled);
      var position = content.offsetTop;
      var scrollHeight = content.scrollHeight;
      console.log('position', position);
      console.log('scrollHeight', scrollHeight);
    },
    f_selectThisProject: function (project_ind) {
      this.d_selectedProjectInd = project_ind;
      this.$forceUpdate();
    },
    f_editProject: function (project_ind) {
      this.wisdom_op = "edit";
      this.data_type = 'wdm3';
      this.wisdom_data = JSON.parse(JSON.stringify(this.d_projectList[project_ind]));
      this.show_wisdom_data_modal = true;
    },
    f_getUserPolestarProjects: function () {
      // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Proje listeniz getiriliyor. Lütfen bekleyiniz.' } });
      ClsPolestar.f_getUserPolestarProjects(this.user.username, 'detailed', this.d_userType)
        .then(resp => {
          // this.$store.commit('Loading', { 'status': false, 'data': {} });
          // console.log(resp);
          this.d_projectList = resp.list;
          this.d_chartList = resp.chart_list;
          this.d_summaryList = resp.summary;
          this.f_showPageData();
        }, resp => {
          alert('error : ', resp);
        });
    },
    f_savePoleStarProject: function () {
      let return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['name', 'require']);
      let wdmr_name = return_data.name;
      if (wdmr_name) {
        this.wisdom_data.label = wdmr_name;
      }
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        let modal_data = {
          'type': 'alert',
          'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.',
          'centered': true,
          'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
        };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' * ' + wdmr_require_alerts[r].label;
        }
        let modal_data = {
          'type': 'alert',
          'text': require_txt,
          'centered': true,
          'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
        };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['name', 'delete']);
      let data = {};
      data = { 'data': this.wisdom_data };
      data.permission_control = { 'value': 'wanalyzer_create-project', 'i_used_this_permission': 1 };
      PoleStarService.save_polestar_project(data).then(resp => {
        if (resp.data.status === "success") {
          this.d_projectList = resp.data.result.project_list;
          this.show_wisdom_data_modal = false;
        } else {
          let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
        this.$forceUpdate();
      });
    },
    f_addNewWdm3Data: function () {
      let data = { 'wdm22_id': this.d_Wdm22_data.id };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
      LayerService.get_user_layer_list(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            if (resp.data.result.wdm246_list && resp.data.result.wdm246_list.length > 0) {
              this.option_data['wdm3']['general']['parameters']['layer']['options'] = resp.data.result.wdm246_list;
            }
            this.data_type = 'wdm3';
            this.wisdom_op = "new";
            let pre_data = {
              'owner': this.user.username,
              'id': 'new',
              'data': {},
              'type': 'wdm3',
              'parent': 'wdm3',
              'reference_wdm': {}
            };
            pre_data['reference_wdm']['version'] = this.option_data['wdm3']['version'];
            pre_data['reference_wdm']['owner_type'] = this.option_data['wdm3']['owner_type'];
            if (this.option_data['wdm3']['owner_type'] === 'wisdom') {
              pre_data['reference_wdm']['key'] = 'wdm_wisdom_wdm3_v' + this.option_data['wdm3'].version;
            }
            for (let i in this.option_data['wdm3'].param_group.param_order) {
              pre_data['data'][this.option_data['wdm3'].param_group.param_order[i]] = {};
            }
            pre_data['data']['general']['status'] = { 'val': { 'label': 'Aktif', 'value': 'active' } }
            pre_data['data']['general']['location'] = {
              'val': {
                'translation': {
                  'en': 'Lokal',
                  'tr': 'Lokal'
                },
                'value': 'local',
                'label': 'Lokal'
              }
            };
            this.wisdom_data = pre_data;
            this.show_wisdom_data_modal = true;
          } else {
            alert('error', resp.data.message);
          }
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    }
  },
  watch: {
    'StoreModal.show': function () {
      if (!this.StoreModal.show && this.StoreModal.data.function_name) {
        if (this.StoreModal.data.function_name === 'f_deletePolestarProjectConfirmed' && this.StoreModal.data.confirm) {
          this.f_deletePolestarProjectConfirmed(this.StoreModal.data.arguments);
        }
      }
    },
    'd_userType': function () {
      this.f_getUserPolestarProjects();
    },
    'd_autoUpdate': function () {
      if (this.d_autoUpdate === 'yes') {
        this.f_getUserPolestarProjects();
        this.f_getProjectDetailsByInterval();
      } else {
        clearInterval(this.d_getProjectListInterval);
      }
    }
  }
};

</script>

<style>
.card-grouping {
  margin: 10px;
  max-width: 300px;
  float: left;
}

.parameter-design-full-screen {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: solid 1px #20a8d8;
  /*background-color: rgba(0, 0, 0, .5);*/
  display: table;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  margin-bottom: 0px;
}

.not-parameter-design-full-screen {
  width: 100%;
  height: 100%;
  border: solid 1px #20a8d8;
  /*background-color: rgba(0, 0, 0, .5);*/
  display: table;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

#chart_content::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #d9e9ea;
}

#chart_content::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

#chart_content::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #4788ac;
}

</style>

